$primary: #0A4066;
$secondary: #2E2E2E;
$tertiary: #B3B324;

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap');

$font-family-sans-serif: 'Open Sans', sans-serif;
$font-family-base: 'Open Sans', sans-serif;
$headings-font-family: 'Open Sans', sans-serif;
$static_header-heading-font-family: 'Open Sans', sans-serif;

/* Flean base imports */
@import "../../flean_base_2019/sass/imports";

